import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormSelect";
import moment from "moment";
import { ASSIGNEE_LIST, STATUS_OPTIONS } from "src/utils/constant";
import {
  setClaimInfo,
  updateClaimNumberStatus,
} from "src/store/slices/claimSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { checkClaimNumberPresent } from "src/api/claimApi";

const ClaimInformation: React.FC<{ claim: any; isEditMode: boolean }> = ({ claim,
  isEditMode,
}) => {
  const dispatch = useAppDispatch();
  const { claimInfo, isUniqueClaimNumber } = useAppSelector(
    (state: RootState) => state.claim
  );
  const { claimNumber, fileNumber, assigneeEmail, dateOfLoss, status, assignee
  } = claimInfo;
  
  
  const handleInputChange = (event: any, key: any) => {
    let value = event.target.value;
    if (key === "dateOfLoss") {
      value = moment(value).format("YYYY-MM-DD");
    }
    if (key === "claimNumber") {
      dispatch(updateClaimNumberStatus());
    }
    dispatch(setClaimInfo({ ...claimInfo, [key]: value }));
  };

  const onChangeDropDown = (value: string, type: any) => {
    if (type === "ASSIGNEE") {
      const assigneObj: any = ASSIGNEE_LIST.find(
        (assignee: any) => assignee.value === value
      );
      dispatch(
        setClaimInfo({
          ...claimInfo,
          assignee: assigneObj["name"],
          assigneeEmail: assigneObj["value"],
        })
      );
    } else {
      const statusObj: any = STATUS_OPTIONS.find(
        (status: any) => status.value === value
      );
      dispatch(setClaimInfo({ ...claimInfo, status: statusObj["value"] }));
    }
  };

  const claimFormData = [
    {
      label: "Ampcus File Number",
      value: fileNumber,
      key: "fileNumber",
      required: true,
    },
    {
      label: "Claim Number",
      value: claimNumber,
      key: "claimNumber",
      required: true,
    },
    {
      label: "Ampcus Forensic Assignee",
      value: assignee,
      isSelect: true,
      type: "ASSIGNEE",
      key: "assignee",
      required: true,
    },
    {
      label: "Date of Loss",
      value: dateOfLoss ? new Date(dateOfLoss).toISOString().split('T')[0] : "",
      isDate: true,
      key: "dateOfLoss",
      required: true,
    },
    {
      label: "Status",
      value: status,
      isSelect: true,
      type: "STATUS",
      key: "status",
      required: true,
    },
  ];

  const onblur = (key: string) => {
    if (key === "claimNumber") {
      dispatch(checkClaimNumberPresent(claimInfo.claimNumber.trim()));
    }
  };
  return (
    <>
      <div>
        <span className="font-bold text-[#045373]">Claim Information</span>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-2">
        {claimFormData.map(
          ({ label, value, isSelect, isDate, type, key, required }) => (
            <div key={label} className="flex flex-col gap-1">
              <span className="flex font-medium text-sm">
                {label} {required && <span className="text-red-500"> *</span>}
              </span>

              {isEditMode ? (
                isSelect ? (
                  type === "ASSIGNEE" ? (
                    <FormSelect
                      name={"ASSIGNEE"}
                      items={ASSIGNEE_LIST}
                      required={required}
                      className="p-0 pl-[16px]text-sm text-blue-gray-700 w-full h-[34px] border-2 rounded-md border-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
                      selectedItem={{
                        name: claimInfo["assignee"],
                        value: claimInfo["assigneeEmail"],
                      }}
                      onChange={(value) => {
                        onChangeDropDown(value, type);
                      }}
                    />
                  ) : (
                    <FormSelect
                      name={"Status"}
                      required={required}
                      items={STATUS_OPTIONS}
                      className="p-0 pl-[16px] text-sm text-blue-gray-700  w-full h-[34px] border-2 rounded-md border-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
                      selectedItem={{
                        name: claimInfo["status"],
                        value: claimInfo["status"],
                      }}
                      onChange={(value) => {
                        onChangeDropDown(value, type);
                      }}
                    />
                  )
                ) : (
                  <>
                    <FormInput
                      disabled={key === "fileNumber" ? true : false}
                      name={key}
                      required={required}
                      type={isDate ? "date" : "text"}
                      maxDateTime={moment(new Date()).format("YYYY-MM-DD")}
                      className={
                        isDate
                          ? "h-[34px] border-2 border-[#b0bec5] focus:border-t-transparent br-top bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                          : "border-2 border-[#b0bec5] border-t-[#b0bec5] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent h-[34px]"
                      }
                      value={value}
                      onChange={(event) => handleInputChange(event, key)}
                      onBlur={() => onblur(key)}
                    />
                    {claimInfo?.claimNumber !== claim?.claimNumber && claimInfo.claimNumber &&
                      key === "claimNumber" &&
                      !isUniqueClaimNumber && (
                        <span className="text-red-600 text-xs -mt-2">
                          Claim number is already used.
                        </span>
                      )}
                  </>
                )
              ) : (
                <span className="font-medium whitespace-nowrap text-sm text-gray-700">
                  {value}
                </span>
              )}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default ClaimInformation;
